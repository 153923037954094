<!--
 * @Author: 张博洋
 * @Date: 2021-09-10 17:50:43
 * @LastEditTime: 2022-02-23 16:37:58
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/userMessage/textMsg.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <!-- <div class="full-w">
    <div class="textMsg"
         style="justify-content: flex-end;"
         v-if="m.from === 100">
      <div style="align-self: center;">
        <van-loading size="22"
                     class="mr5"
                     v-if="m.loadingShow" />
      
      </div>
      <div class="text"
           style="background: #1ab370;">{{m.text}}</div>
      <div class="avatar  ml8 mr15">
        <img :src="$.imgs.userAvatar"
             alt="">
      </div>

    </div>

    <div class="textMsg"
         style="justify-content: flex-start;"
         v-else>
      <div class="avatar ml15 mr8">
        <img :src="docAvatar"
             alt="">
      </div>
      <div class="text"
           style="background: #fff; color:#333">{{m.text}}</div>
    </div>
  </div> -->

  <div class="text"
       style="background: #1ab370;">{{m.text}}</div>
</template>
<script>
export default {
  props: ['message', 'docAvatar'],
  data() {
    return {
      m: null,
    }
  },

  created() {
    this.m = this.message
  },
}
</script>

<style lang="scss" scoped>
.right {
  justify-content: flex-end;
}

.left {
  justify-content: flex-start;
}
.text {
  max-width: 249px;
  min-height: 44px;
  border-radius: 4px;
  color: #fff;
  padding: 8px 12px;
  line-height: 2;
  word-wrap: break-word;
  word-break: break-all;
}
</style>