/*
 * @Author: 张博洋
 * @Date: 2021-09-27 16:30:57
 * @LastEditTime: 2021-09-27 16:31:08
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /hospital-h5/src/public/yb-im-sdk/src/util/bus.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import Vue from 'vue'
const bus = new Vue()
export default bus 
