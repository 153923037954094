<!--
 * @Author: 张博洋
 * @Date: 2021-09-10 17:50:43
 * @LastEditTime: 2022-02-23 17:14:29
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/userMessage/imageMsg.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>

  <div class="img">
    <img :src="message.url"
         alt=""
         @click="previewImage(message.url)" />
  </div>

</template>
<script>
import { ImagePreview } from 'vant'
export default {
  props: ['message'],
  data() {
    return {
      // m: null,
    }
  },

  created() {},
  methods: {
    formatImage(imageUrls) {
      if (imageUrls) {
        return imageUrls.split(',')
      }
      return []
    },
    previewImage(img) {
      ImagePreview({
        images: [img],
        closeable: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.right {
  justify-content: flex-end;
}

.left {
  justify-content: flex-start;
}
.img {
  max-width: 130px;
  max-height: 130px;
  img {
    max-width: 130px;
    max-height: 130px;
  }
}
</style>