<!--
 * @Author: 张博洋
 * @Date: 2021-08-30 18:10:07
 * @LastEditTime: 2022-01-13 17:24:49
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/historyList.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <WaitingPage :state="state"
                 :serviceOrderEndTime="serviceOrderEndTime"
                 @notifyFinsh="notifyFinsh"></WaitingPage>
    <div class="im-list"
         id="imListRefresh">
      <!-- <van-pull-refresh v-model="isLoading"
                        @refresh="onRefresh"
                        style="min-height: 100%;">
        <ImList :list="list"
                :docAvatar="docAvatar"></ImList>
      </van-pull-refresh> -->
      <app-refresh ref="downRefresh"
                   class="im-refresh"
                   :refresh-num='200'
                   :refresh="canPull"
                   @downRefresh="onRefresh"
                   :ajax-time='0'>
        <ImList :list="list"
                :docAvatar="docAvatar"></ImList>
      </app-refresh>
    </div>

  </div>
</template>

<script>
import WaitingPage from './components/waiting.vue'
import ImList from './components/imList.vue'
import $ from 'jquery'
import bus from '@/public/bus'
export default {
  components: {
    WaitingPage,
    ImList,
  },
  data() {
    return {
      im: null,
      roomId: '',
      list: [],
      pageNo: 1,
      pageSize: 20,
      voiceIcon: '../../static/img/voice-icon.svg',
      keyboardIcon: '../../static/img/keyboard-icon.svg',
      value: '',
      popShow: false,
      addShow: true,
      addIconShow: true,
      payload: {
        from: 100,
        to: [0],
      },
      fileList: [],
      docAvatar: '',
      sendAreaShow: false,
      state: '',
      orderid: '',
      serviceOrderEndTime: 0,
      isLoading: false,
      canPull: true,
      timeValue: 5 * 60 * 1000,
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val.length) {
          this.addIconShow = false
        } else {
          this.addIconShow = true
        }
      },
    },
    sendAreaShow: {
      handler(val) {
        this.sendAreaShow = val
      },
    },
  },
  created() {
    console.log('历史历史历史历史历史历史历史历史历史', this.$route.query)
    this.getTimeAndState()
    this.getDoctorBaseInfo()
    this.roomId = this.$route.query.roomId

    localStorage.setItem('state', this.$route.query.state)
    // 初始化 SDK 实例
    this.pageNo = 1
    this.getMsg().then((res) => {
      console.log('1111')
      this.$nextTick(() => {
        this.goMessageBottom()
      })
    })
  },
  mounted() {},
  activated() {
    this.getTimeAndState()
    this.getDoctorBaseInfo()
    setTimeout(() => {
      this.goMessageBottom()
    }, 100)
  },

  methods: {
    getTimeAndState() {
      this.$axios({
        url: 'order/getOrderStateAndTime',
        data: {
          serviceOrderId: this.$route.query.orderid,
        },
        elseData: {
          ajaxLoading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.serviceOrderEndTime = res.d.serviceOrderEndTime
        this.state = res.d.state
      })
    },
    handleMessage(data) {
      console.log(data)
      if (JSON.parse(data.text).messageType === 33) {
        this.getTimeAndState()
        this.sendAreaShow = true
        this.state = 3
      }
      if (JSON.parse(data.text).messageType === 999) {
        // alert()
        this.sendAreaShow = false
        this.state = JSON.parse(data.text).state
      }
      let message = {}
      // 如果不是患者发的
      if (JSON.parse(data.text).from !== 100) {
        console.log('=======', JSON.parse(data.text))
        // console.log('=======', this.list)
        if (
          JSON.parse(data.text).sendTime -
            this.list[this.list.length - 1].sendTime >
          this.timeValue
        ) {
          Object.assign(
            message,
            JSON.parse(data.text),
            { messageId: data.id },
            { expend: true }
          )
        } else {
          Object.assign(message, JSON.parse(data.text), { messageId: data.id })
        }
        console.log('=======', message)
        this.list = [...this.list, message]

        this.goMessageBottom()
      } else {
        console.log(this.list)
        bus.$emit('message', false)
      }

      // 过滤不是给患者的消息
      this.list = this.list.filter((item) => {
        return (
          item.to.indexOf(100) !== -1 ||
          (item.to.indexOf(0) !== -1 && item.messageType !== 9)
        )
      })
    },

    async getMsg() {
      return new Promise(async (resolve) => {
        this.$axios({
          url: 'message/getListHistory',
          data: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            roomId: this.roomId,
            orderId: this.$route.query.orderid,
          },
          elseData: {
            // loading: true,
            ajaxLoading: true,
          },
          _self: this,
        }).then((res) => {
          if (res.d.data.length < 10 || !res.d.data.length) {
            this.isLoading = false
            this.canPull = false
          }
          let arr = res.d.data.reverse()
          console.log(arr)
          console.log(res.d.data)

          this.list = [...arr, ...this.list]
          var obj = {}
          this.list = this.list.reduce(function (item, next) {
            obj[next.messageId]
              ? ''
              : (obj[next.messageId] = true && item.push(next))
            return item
          }, [])

          this.list.filter((item) => {
            return item.to.indexOf(100) !== -1 || item.to.indexOf(0) !== -1
          })

          console.log(this.list)
          for (let i = 0; i < this.list.length; i++) {
            if (i === 0) {
              this.list[i].expend = true
            } else {
              if (
                this.list[i].sendTime - this.list[i - 1].sendTime >
                this.timeValue
              ) {
                this.list[i].expend = true
              }
            }
          }
          console.log(this.list)
          this.isLoading = false
          resolve(this.list)
        })
      })
    },
    //回到消息底部
    goMessageBottom() {
      this.$nextTick(() => {
        console.log('---------------------------------------')
        let scrollDom = document.querySelector('#imListRefresh')
        let nowDom =
          scrollDom.querySelectorAll('.message-box')[this.list.length - 1]
        console.log(this.$.getPositiont(nowDom).top)
        console.log(nowDom)
        console.log(scrollDom.clientHeight)
        // if (this.$.getPositiont(nowDom).top > scrollDom.clientHeight) {
        //   // scrollDom.scrollTop = this.$.getPositiont(nowDom).top - scrollDom.clientHeight + nowDom.clientHeight + 30
        //   $('#imListRefresh').animate({
        //     scrollTop: this.$.getPositiont(nowDom).top - scrollDom.clientHeight + nowDom.clientHeight + 10
        //   }, 200)
        // }
        // if(this.$.getPositiont(nowDom).top < scrollDom.clientHeight)
        $('#imListRefresh').animate(
          {
            scrollTop:
              this.$.getPositiont(nowDom).top -
              scrollDom.clientHeight +
              nowDom.clientHeight +
              10,
          },
          200
        )
        // scrollDom.scrollTop = this.$.getPositiont(nowDom).top + scrollDom.scrollTop + 44
        // scrollDom.scrollTop = 1000
      })
    },

    // 获取医生基本信息
    getDoctorBaseInfo() {
      this.$axios({
        url: 'doctor/getBaseDoctorById',
        data: {
          id: this.$route.query.doctorAccountId,
        },
        elseData: {
          ajaxLoading: true,
        },
      }).then((res) => {
        this.docAvatar = res.d.avatar
        this.$.setTitle(res.d.doctorName + ' ' + res.d.technicalName)
      })
    },
    notifyFinsh(payload) {
      console.log(payload)
      this.sendAreaShow = payload
    },
    onRefresh() {
      if (!this.canPull) {
        this.isLoading = false
        this.$refs.downRefresh.downRefreshOver(true) //执行加载完成方法！
        return
      }
      this.pageNo++
      this.getMsg().then(() => {
        this.$refs.downRefresh.downRefreshOver(true) //执行加载完成方法！
      })
    },
    // addReplyTime (arr) {
    //   let temp = arr[0].sendTime
    //   console.log(temp)
    //   for (let i = 0; i < arr.length; i++) {
    //     if (i === 0) {
    //       this.arr[i].expend = true
    //     } else {
    //       if (arr[i].sendTime - temp > this.timeValue) {
    //         arr[i].expend = true
    //         temp = arr[i].sendTime
    //       }
    //     }
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
.im-list {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  background-color: #eee;
  .im-refresh {
    position: relative;
  }
}
.send-area {
  background: #fff;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .icon {
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .chat-input {
    height: 40px;
    background: #f5f5f5;
    border-radius: 20px;
    flex: 1;
    /deep/.van-field__body {
      height: 100%;
    }
  }
  .send {
    width: 60px;
    height: 30px;
    background: #1ab370;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    border-radius: 16px;
    transition: width 1s;
    // transition: display 1s;
  }
}
.addDiv {
  height: 260px;
  background: #f7f7f7;
  display: flex;
  transition: height 0.2s;
  & > div {
    position: relative;
    margin: 40px 0 0 30px;
    height: fit-content;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
    text-align: center;
  }
  .image-upload {
    width: 100%;
    height: 100%;

    /deep/ .van-uploader__input-wrapper {
      // position: absolute;
      // left: 0;
      // top: 0;
    }
    /deep/.van-uploader {
      width: 100%;
      height: 100%;
    }
  }
}
.h0 {
  height: 0px !important;
  transition: height 0.2s;
}
</style>