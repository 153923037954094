<!--
 * @Author: 张博洋
 * @Date: 2022-01-10 20:09:51
 * @LastEditTime: 2022-01-10 20:13:38
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/sysMessage/CommonLanguage.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="common">{{message.title}}</div>
</template>

<script>
export default {
  props: ['message'],
}
</script>

<style lang="scss" scoped>
.common {
  width: auto;
  padding: 8px 12px;
  background: rgba(133, 133, 133, 0.1);
  border-radius: 8px;
  margin: 0 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 14px;
}
</style>