<!--
 * @Author: 张博洋
 * @Date: 2021-09-09 17:07:11
 * @LastEditTime: 2021-09-13 22:59:27
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/im/components/sysMessage/HealthDocNotify.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="healthDocNotify"
       @click="inputRecord">
    <img width="32"
         height="32"
         src="../../../../static/img/record-notify.png"
         alt="">
    <div class="ml12">请您填写健康档案，以便深入<span class="underline">了解您</span></div>
  </div>
</template>

<script>
export default {
  props: ['message'],
  methods: {
    inputRecord () {
      this.$router.push({
        name: 'archives.edit',
        query: {
          patientId: this.message.patientId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.healthDocNotify {
  width: auto;
  padding: 8px 12px;
  background: #fff;
  margin: 0 64px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  & > div {
    color: #333;
    font-size: 14px;
    span {
      color: #1ab370;
      text-decoration: underline;
    }
  }
}
</style>