<!--
 * @Author: 张博洋
 * @Date: 2021-09-08 19:12:58
 * @LastEditTime: 2021-11-30 16:56:02
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/im/components/sysMessage/PatientInfo.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="patientInfo">
    <div class="info">
      <span class="name">{{m.patientName}}</span><span class="ml12">({{m.patientSex === 1  ? '男' : '女'}} {{m.patientAge}}岁)</span>
    </div>
    <div class="desc mt14">{{m.patientDiseaseDesc}}</div>
    <div class="image">
      <h1>图片</h1>
      <div class="images"
           ref="images">
        <div class="img"
             v-for="(img, imgIndex) in m.urls"
             :key="imgIndex">
          <img :src="img"
               :width="imgWidth"
               :height="imgWidth"
               alt=""
               @click="previewImage(img)">
        </div>
      </div>
    </div>
    <div class="pay"
         v-if="state === 1"></div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {

  props: ['message', 'state'],
  data () {
    return {
      maxWidth: 327,
      imgWidth: '',
    }
  },
  computed: {
    m () {
      return this.message
    }
  },
  methods: {
    previewImage (img) {
      ImagePreview({
        images: [img],
        closeable: true
      });
    },
  },
  mounted () {
    console.log(this.$refs.images.clientWidth)
    this.maxWidth = this.$refs.images.clientWidth
    this.imgWidth = Math.floor((this.maxWidth - 22) / 3)
  }
}
</script>

<style lang="scss" scoped>
.patientInfo {
  width: auto;
  padding: 14px 8px;
  background: #fff;
  margin: 0 64px;
  border-radius: 8px;
  .info {
    display: flex;
    align-items: center;
    .name {
      max-width: 140px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.desc {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 8px;
}
.image {
  h1 {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin: 14px 0;
  }
  .images {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .img {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .img:nth-child(3n) {
      margin-right: 0px;
    }
  }
}
</style>
