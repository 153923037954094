<!--
 * @Author: 张博洋
 * @Date: 2021-09-07 14:09:09
 * @LastEditTime: 2022-01-20 14:53:14
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/sysMessage/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="sys-message">
    <component :is="currentMessage"
               :message="message"
               :state="state"></component>
  </div>
</template>

<script>
// import Revoke from './revoke';
import HealthDocComplete from './HealthDocComplete.vue'
import HealthDocNotify from './HealthDocNotify'
import PatientInfo from './PatientInfo'
import ServiceEnd from './ServiceEnd'
import RpPass from './RpPass'
import CaseHistoryComplete from './CaseHistoryComplete'
import ServiceStart from './ServiceStart'
import Evaluate from './Evaluate.vue'
import CheckEvaluate from './CheckEvaluate.vue'
import CommonLanguage from './CommonLanguage.vue'
export default {
  props: ['message', 'state'],

  created() {
    // console.log(this.message)
  },
  components: {
    PatientInfo,
    HealthDocComplete,
    HealthDocNotify,
    ServiceEnd,
    RpPass,
    CaseHistoryComplete,
    ServiceStart,
    Evaluate,
    CheckEvaluate,
    CommonLanguage,
  },
  data() {
    return {
      renderComponent: {
        1: 'TextMsg',
        2: 'Image',
        3: 'Voice',
        10: 'PatientInfo',
        20: 'HealthDocNotify',
        30: 'HealthDocComplete',
        33: 'ServiceStart',
        40: 'CaseHistoryComplete',
        50: 'MedicationSuggest',
        60: 'MedicationSuggestReject',
        70: 'Rp',
        80: 'RpReject',
        90: 'RpPass',
        210: 'CaseHistorySave',
        220: 'MedicationSuggestSave',
        230: 'RpSave',
        999: 'ServiceEnd',
        1000: 'Evaluate',
        1001: 'CheckEvaluate',
        2000: 'CommonLanguage',
      },
    }
  },
  computed: {
    currentMessage() {
      const { messageType } = this.message
      return this.renderComponent[messageType]
    },
  },
}
</script>
