<!--
 * @Author: 张博洋
 * @Date: 2021-09-07 13:56:00
 * @LastEditTime: 2022-01-14 15:17:48
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/message.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="message-box mt20 mb10">
    <div class="time mt30 mb30"
         v-if="message.expend ">{{handleTime(message.sendTime)}}</div>

    <component :is="currentMessage"
               :message="message"
               :docAvatar="docAvatar"
               :state="state">
    </component>

  </div>
</template>

<script>
import UserMessage from './userMessage/index.vue'
import SysMessage from './sysMessage/index.vue'
const messageTypeToComponent = {
  user: 'UserMessage',
  system: 'SysMessage',
}
export default {
  data() {
    return {}
  },
  props: {
    message: Object,
    docAvatar: String,
    c: Number,
    state: Number,
  },
  components: {
    UserMessage,
    SysMessage,
  },
  computed: {
    currentMessage() {
      const { from, to } = this.message
      if (from === 999 && (to.indexOf(100) !== -1 || to.indexOf(0) !== -1)) {
        // console.log('系统to患者')
        // console.log(messageTypeToComponent['system'])
        return messageTypeToComponent['system']
      } else if (
        from !== 999 &&
        from !== 100 &&
        (to.indexOf(100) !== -1 || to.indexOf(0) !== -1)
      ) {
        // console.log('医生to患者')
        // console.log(messageTypeToComponent['user'])
        return messageTypeToComponent['user']
      } else if (
        from === 100 &&
        (to.indexOf(201) !== -1 ||
          to.indexOf(202) !== -1 ||
          to.indexOf(0) !== -1)
      ) {
        // console.log('患者to医生')
        // console.log(messageTypeToComponent['user'])
        return messageTypeToComponent['user']
      }
    },
  },
  created() {
    // console.log(this.c)
  },
  methods: {
    // log (message) {
    //   // console.log('index.vue', message)
    // },
    handleTime(sendTime) {
      const toDay = this.$.moment(sendTime).isSame(this.nowDay, 'day')
      const yesterday = this.$.moment(sendTime)
        .add(1, 'days')
        .isSame(this.nowDay, 'day')
      let showTime = this.$.moment(sendTime).format('YYYY-MM-DD HH:mm:ss')
      if (toDay) showTime = this.$.moment(sendTime).format('HH:mm:ss')
      if (yesterday) showTime = this.$.moment(sendTime).format('昨天 HH:mm:ss')
      return showTime
    },
  },
  // props: ['message', 'diagInfo'],
}
</script>

<style lang="scss" scoped>
.message-box {
  .time {
    text-align: center;
    color: #999;
    font-size: 12px;
  }
}
</style>