<!--
 * @Author: 张博洋
 * @Date: 2021-09-11 18:02:11
 * @LastEditTime: 2021-11-25 19:50:12
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/im/components/sysMessage/RpPass.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="healthDocComplete"
       @click="toRp">
    <img width="32"
         height="32"
         src="../../../../static/img/rp-icon.png"
         alt="">
    <div class="ml12">已为您开处方，<span class="underline">请您查看</span></div>
  </div>
</template>

<script>
export default {
  props: ['message'],
  created () {
    console.log(this.message)
  },
  methods: {
    toRp () {
      this.$router.push({
        name: 'rp',
        query: {
          rpId: this.message.rpId
        }
      })
    //   wx.miniProgram.navigateTo({
    //     url: `/pages/protocol/protocol?rpId=${this.message.rpId}&showBuyDrug=true`
    //   });
    }
  }
}
</script>

<style lang="scss" scoped>
.healthDocComplete {
  width: auto;
  padding: 8px 12px;
  background: #fff;
  margin: 0 64px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  & > div {
    color: #333;
    font-size: 14px;
    span {
      color: #1ab370;
      text-decoration: underline;
    }
  }
}
</style>