<!--
 * @Author: 张博洋
 * @Date: 2021-09-07 09:56:47
 * @LastEditTime: 2022-01-20 13:51:51
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/imList.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <!-- <div class="pr full-h"> -->
  <!-- <app-refresh scrollId='imListRefresh'
                 ref="downRefresh"
                 :refresh-num='10'
                 class="bg-bg"
                 :refresh="canPull"
                 @downRefresh="onRefresh"
                 :ajax-time='0'
                 loading-type='simple'> -->
  <MessageList :list="list"
               :docAvatar="docAvatar"
               :state="Number(state)"></MessageList>
  <!-- </app-refresh> -->
  <!-- </div> -->
</template>

<script>
import MessageList from '../components/messageList'
export default {
  data() {
    return {
      // canPull: true,
    }
  },
  // props: {
  //   list: Array,
  //   docAvatar: String,
  //   state: Number
  // },
  props: ['list', 'docAvatar', 'state'],
  components: {
    MessageList,
  },
  watch: {
    list: {
      handler(val) {
        // alert()
      },
    },
  },
  methods: {
    //下拉加载更多数据
    // async onRefresh () {
    //   this.$emit('notifyRefresh')
    // },
  },
}
</script>