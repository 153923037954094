<!--
 * @Author: 张博洋
 * @Date: 2021-12-25 14:13:58
 * @LastEditTime: 2021-12-25 15:31:49
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/sysMessage/CheckEvaluate.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="evaluate">
    <p>请为本次医生的服务做出评价吧</p>
    <span @click="checkEvaluate">查看评价</span>

    <evaluate-panel :evaluateShow="evaluateShow"
                    :orderId="message.id"
                    :evaluateDetail="evaluateDetail"
                    @close="closePanel"
                    :evaluateType="evaluateType"></evaluate-panel>
  </div>
</template>

<script>
import evaluatePanel from '../../../order/components/evaluatePanel.vue'
export default {
  props: ['message'],
  components: {
    evaluatePanel,
  },
  data() {
    return {
      evaluateShow: false,
      evaluateType: '',
      evaluateDetail: {},
    }
  },
  created() {
    console.log(this.message)
    // this.getEvaluateDetail()
  },
  activated() {
    console.log(this.message)
  },
  methods: {
    checkEvaluate() {
      this.evaluateShow = true
      this.evaluateType = 'check'
      this.getEvaluateDetail()
    },
    closePanel() {
      this.evaluateShow = false
    },
    getEvaluateDetail() {
      this.$axios({
        type: 'post',
        url: 'order/queryEvaluate',
        data: {
          id: this.message.orderId,
        },
      }).then((res) => {
        console.log(res)
        this.evaluateDetail = res.d
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.evaluate {
  width: auto;
  padding: 8px 12px;
  background: rgba(133, 133, 133, 0.1);
  border-radius: 8px;
  margin: 0 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #999;
  font-size: 14px;
  span {
    color: #1ab370;
    text-decoration: underline;
  }
}
</style>