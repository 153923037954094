<!--
 * @Author: 张博洋
 * @Date: 2021-09-07 13:55:11
 * @LastEditTime: 2022-01-20 15:14:23
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/messageList.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="message-list">
    <template v-for="(item,index) in list">

      <Message :key="item.messageId"
               :c="index"
               :message="item"
               :docAvatar="docAvatar"
               :state="state"></Message>
    </template>
  </div>
</template>


<script>
import Message from './message'

export default {
  props: {
    list: Array,
    docAvatar: String,
    state: Number,
  },
  data() {
    return {}
  },
  components: {
    Message,
  },

  methods: {
    //时间戳
    // addReplyTime (arr) {
    //   const length = arr.length
    //   if (length <= 0) {
    //     return
    //   }
    //   this.$set(arr[0], 'expend', true)
    //   if (length > 1) {
    //     for (let i = 1; i < length; i++) {
    //       if (arr[i].sendTime - arr[i - 1].sendTime > 1 * 10 * 1000) {
    //         this.$set(arr[i], 'expend', true)
    //         // if (length - 1 === i + 1) {
    //         //     this.$set(arr[i + 1], "expend", true);
    //         // }
    //       }
    //     }
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
.message-list {
  overflow: auto;
  // background-color: #eee;
}
</style>
