<!--
 * @Author: 张博洋
 * @Date: 2022-02-23 16:37:21
 * @LastEditTime: 2022-02-25 18:07:15
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/views/im/components/userMessage/sendForm.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <div class="sendVisitTaskForm">
    <img width="32"
         height="32"
         class="mt4"
         src="../../../../static/img/form-notify.svg"
         alt="">
    <div class="ml12 mt4">
      <h1>{{message.taskTitle}}</h1>

      <p>随访表单已发送患者，请填写完成。<span class="underline"
              @click="fillForm(message)">点击查看</span></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message'],
  data() {
    return {
      // m: null,
    }
  },

  created() {},
  methods: {
    async fillForm(m) {
      this.$axios({
        type: 'POST',
        url: 'visitTask/detectedVisitForm',
        data: {
          taskId: m.taskId,
        },
      })
        .then((res) => {
          this.$router.push({
            name: 'follow.fill',
            query: {
              taskId: m.taskId,
              accountType: 3,
              accountId: this.$route.query.patientAccountId,
              visitType: 2,
            },
          })
        })
        .catch((err) => {
          return err
        })
    },
  },
}
</script>


<style lang="scss" scoped>
.sendVisitTaskForm {
  width: auto;
  max-width: 240px;
  padding: 8px 12px 12px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  h1 {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
  }
  p {
    font-size: 12px;
    color: #666666;
    span {
      text-decoration: underline;
      color: rgba(26, 179, 112, 1);
    }
  }
}
</style>
