<!--
 * @Author: 张博洋
 * @Date: 2021-12-21 15:28:56
 * @LastEditTime: 2022-01-04 14:20:17
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/order/components/evaluatePanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<!--
 * @Author: 张博洋
 * @Date: 2021-08-16 12:06:12
 * @LastEditTime: 2021-12-13 16:41:07
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/patient/components/idCardTypePanel.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <van-popup v-model="show"
             round
             position="bottom"
             refreshable
             refresh-on-popstate
             :refresh-on-click-overlay="true"
             @close="closePanel"
             :overlay="true"
             get-container="body"
             class="evaluate-panel">
    <div class="title">{{title}}</div>

    <div class="rate mt12">
      <span class="mr24">医生评价</span>
      <van-rate v-model="rate"
                @change="handleRate"
                :readonly="evaluateType === 'check'"
                allow-half
                color="#FFCC00"
                void-icon="star"
                void-color="#eee" />
      <span class="score ml24">{{rate * 2}}分</span>
    </div>

    <div class="tag mt14"
         v-if="type === 'add'">
      <template v-for="(i,index) in params.tagItems">
        <span :class="i.checked === 1 ? 'active' : ''"
              :key="index"
              @click="select(index)">{{i.tag}}</span>
      </template>
    </div>

    <div class="tag mt14"
         v-if="type === 'check'">
      <template v-for="(i,index) in params.tagItems">
        <span class="active"
              :key="index"
              v-if="i.checked === 1">{{i.tag}}</span>
      </template>
    </div>

    <van-field v-if="evaluateType === 'add'"
               class="message"
               v-model="params.content"
               rows="2"
               maxlength="50"
               show-word-limit
               autosize
               type="textarea"
               placeholder="您的反馈能让我们改进不足，做的更好。" />
    <div :class="[params.content.length > 0 ? 'message-check' : '']"
         v-if="evaluateType === 'check'">{{params.content}}</div>
    <van-button v-if="
         evaluateType==='add'"
                class="
         mt40"
                type="primary"
                @click="commit"
                round>提交</van-button>
  </van-popup>
</template>
<script>
import rate from '@/mixin/Rate.js'
export default {
  name: 'evaluate',
  props: ['evaluateShow', 'orderId', 'evaluateDetail', 'evaluateType'],

  mixins: [rate],
  data() {
    return {
      show: this.evaluateShow,
      title: '请给医生评价',
      rate: 0,
      params: {
        score: 0,
        content: '',
        tagItems: [
          {
            tag: '医术精湛',
            checked: 0,
          },
          {
            tag: '十分敬业',
            checked: 0,
          },
          {
            tag: '值得信赖',
            checked: 0,
          },
          {
            tag: '医德高尚',
            checked: 0,
          },
          {
            tag: '非常满意',
            checked: 0,
          },
        ],
      },
      originParams: {
        score: 0,
        content: '',
        tagItems: [
          {
            tag: '医术精湛',
            checked: 0,
          },
          {
            tag: '十分敬业',
            checked: 0,
          },
          {
            tag: '值得信赖',
            checked: 0,
          },
          {
            tag: '医德高尚',
            checked: 0,
          },
          {
            tag: '非常满意',
            checked: 0,
          },
        ],
      },
      isSelect: false,
      type: '',
    }
  },
  watch: {
    evaluateShow(val) {
      this.show = val
    },
    evaluateDetail(val) {
      console.log(Boolean(val))
      if (!Boolean(val)) return
      this.rate = val.score / 2
      this.params.score = val.score
      this.params.content = val.content
      this.params.tagItems = [...val.tagItems]
      this.params.orderId = val.orderId
    },
    evaluateType: {
      handler(val) {
        console.log(val)
        this.type = val
        if (val === 'add') {
          this.title = '请给医生评价'
          this.params = this.$._.cloneDeep(this.originParams)
        } else if (val === 'check') {
          this.title = '综合评价'
        }
        // this.title = '综合评价'
        // console.log(Boolean(val))
        // console.log(val)
        // if (!Boolean(val)) return
        // this.params.score = val.score
        // this.params.content = val.content
        // this.params.tagItems = [...val.tagItems]
        // this.params.orderId = val.orderId
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    handleRate(val) {
      console.log(val)
      this.params.score = val * 2
    },
    closePanel() {
      this.$emit('close')
    },
    closePopup() {
      console.log('关闭弹窗')
      this.$emit('close', this.type)
    },
    select(index) {
      if (this.type === 'check') return
      console.log(this.params.tagItems[index])
      if (this.params.tagItems[index].checked === 0) {
        this.params.tagItems[index].checked = 1
      } else {
        this.params.tagItems[index].checked = 0
      }
    },
    commit() {
      let finshTag = this.params.tagItems.find((item) => item.checked === 1)
      console.log(finshTag)
      if (this.params.score === 0) {
        this.$.toast.warning('请给医生评分')
        return
      }
      if (finshTag === undefined && this.params.content === '') {
        this.$.toast.warning('请给医生评价')
        return
      }
      this.params.orderId = this.orderId
      this.$axios({
        type: 'post',
        url: 'order/addEvaluate',
        data: this.params,
        elseData: {
          ajaxLoading: true,
        },
      }).then((res) => {
        console.log(res)
        this.$.toast.success('评价成功')
        this.$emit('refresh')
        this.params = this.$._.cloneDeep(this.originParams)
        this.rate = 0
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.evaluate-panel {
  display: flex;
  flex-direction: column;
  padding: 12px 30px 24px 30px;
  .title {
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .rate {
    font-size: 14px;
    color: #666666;
    padding: 12px 0;
    display: flex;
    align-items: flex-end;
    .score {
      color: #333;
    }
  }
  .tag {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    span {
      height: 32px;
      line-height: 32px;
      background: #f5f5f5;
      border-radius: 4px;
      text-align: center;
      margin-right: 15px;
      width: 106px;
      margin-bottom: 15px;
    }
    span.active {
      background: rgba(26, 179, 112, 0.1);
      color: #1ab370;
    }
    span:nth-child(3n) {
      margin-right: 0;
    }

    @media screen and (max-width: 414px) {
      span {
        width: 94px;
      }
    }
    @media screen and (max-width: 375px) {
      span {
        width: 90px;
      }
    }
    @media screen and (max-width: 360px) {
      span {
        width: 77px;
      }
    }
    @media screen and (max-width: 320px) {
      span {
        width: 75px;
      }
    }
  }
  .message {
    border-radius: 8px;
    border: 1px solid #eeeeee;
    font-size: 14px;
  }
  .message-check {
    border-radius: 8px;
    background: rgba(245, 245, 245, 0.5);
    border: 1px solid #eeeeee;
    padding: 10px 16px;
  }
  .van-field--disabled {
    color: #333;
  }
}
</style>
