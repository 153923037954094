<!--
 * @Author: 张博洋
 * @Date: 2021-09-09 17:04:47
 * @LastEditTime: 2022-02-23 17:20:03
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/sysMessage/HealthDocComplete.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <div class="healthDocComplete"
       @click="toRecordDetail">
    <img width="32"
         height="32"
         src="../../../../static/img/record-icon-w.png"
         alt="">
    <div class="ml12">我完善了健康档案，<span class="underline">请您查看</span></div>
  </div>
</template>

<script>
export default {
  props: ['message'],
  methods: {
    toRecordDetail() {
      this.$router.push({
        name: 'patient.archives',
        query: {
          id: this.message.patientId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.healthDocComplete {
  width: auto;
  padding: 8px 12px;
  background: #1ab370;
  margin: 0 64px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  & > div {
    color: #fff;
    font-size: 14px;
    span {
      text-decoration: underline;
    }
  }
}
</style>