<!--
 * @Author: 张博洋
 * @Date: 2021-09-07 14:10:47
 * @LastEditTime: 2022-02-25 16:39:47
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/userMessage/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="user-message">
    <div style="display:flex; justify-content: flex-end;"
         v-if="message.from === 100">
      <div style="align-self: center;">
        <van-loading size="22"
                     class="mr5"
                     v-if="m.loadingShow" />
      </div>
      <component :is="currentMessage"
                 :message="message"
                 :docAvatar="docAvatar"></component>
      <div class="avatar  ml8 mr15">
        <img :src="$.imgs.userAvatar"
             alt="">
      </div>
    </div>

    <div style="display:flex; justify-content: flex-start;"
         v-else>

      <div class="avatar  mr8 ml15">
        <img :src="docAvatar"
             alt="">
      </div>
      <component :is="currentMessage"
                 :message="message"
                 :docAvatar="docAvatar"></component>
      <div style="align-self: center;">
        <van-loading size="22"
                     class="mr5"
                     v-if="m.loadingShow" />
      </div>
    </div>

  </div>
</template>


<script>
// import Revoke from './revoke';
// import HealthDocComplete from './HealthDocComplete';
// import HealthDocNotify from './HealthDocNotify'
// import PatientInfo from './PatientInfo';
import bus from '@/public/bus'
import TextMsg from './textMsg.vue'
import ImageMsg from './imageMsg.vue'
import SendVisitTaskForm from './sendForm.vue'
import FinishVisitTaskForm from './finishForm.vue'
export default {
  props: ['message', 'docAvatar'],
  data() {
    return {
      m: null,
    }
  },

  created() {
    this.m = this.message
    bus.$on('message', (e) => {
      console.log(e)
      this.m.loadingShow = false
    })
  },
  components: {
    TextMsg,
    ImageMsg,
    SendVisitTaskForm,
    FinishVisitTaskForm,
  },
  data() {
    return {
      renderComponent: {
        1: 'TextMsg',
        2: 'ImageMsg',
        3: 'Voice',
        10: 'PatientInfo',
        20: 'HealthDocNotify',
        30: 'HealthDocComplete',
        40: 'CaseHistoryComplete',
        50: 'MedicationSuggest',
        60: 'MedicationSuggestReject',
        70: 'Rp',
        80: 'RpReject',
        90: 'RpPass',
        210: 'CaseHistorySave',
        220: 'MedicationSuggestSave',
        230: 'RpSave',
        999: 'ServiceEnd',
        1002: 'SendVisitTaskForm',
        1003: 'FinishVisitTaskForm',
      },
    }
  },
  computed: {
    currentMessage() {
      const { messageType } = this.message
      // console.log(this.renderComponent[messageType])
      return this.renderComponent[messageType]
    },
  },
}
</script>

<style lang="scss" scoped>
.user-message {
  display: flex;
  flex-direction: column;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 40px;
    height: 40px;
  }
}
</style>
