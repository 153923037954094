<!--
 * @Author: 张博洋
 * @Date: 2022-02-24 10:46:17
 * @LastEditTime: 2022-02-24 11:22:00
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/userMessage/finishForm.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="finishVisitTaskForm">
    <img width="32"
         height="32"
         class="mt4"
         src="../../../../static/img/form-notify-g.svg"
         alt="">
    <div class="ml12 mt4">

      <p>我已完成随访表单填写 <br><span class="underline"
              @click="finishForm(message)">点击查看</span></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message'],
  data() {
    return {
      // m: null,
    }
  },

  created() {},
  methods: {
    finishForm(m) {
      this.$router.push({
        name: 'follow.detail',
        query: {
          taskId: m.taskId,
          accountType: this.$route.query.accountType || 3,
          accountId: this.$route.query.patientAccountId,
        },
      })
    },
  },
}
</script>


<style lang="scss" scoped>
.finishVisitTaskForm {
  width: 240px;
  max-width: 240px;
  padding: 8px 12px 12px;
  background: rgba(26, 179, 112, 1);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;

  p {
    font-size: 12px;
    color: #fff;
    span {
      text-decoration: underline;
      color: #fff;
    }
  }
}
</style>>
