<!--
 * @Author: 张博洋
 * @Date: 2021-09-06 19:09:12
 * @LastEditTime: 2022-02-25 17:18:56
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/im/components/waiting.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <div class="wait"
       v-if="Number(orderType) === 2">
    <!-- {{state === '3'}} -->
    <div v-if="newState === 2"
         style="justify-content: flex-start;">
      <van-icon name="underway-o"
                color="#FF8200" />
      请等待医生问诊！
    </div>

    <div v-if="newState === 4"
         style="justify-content: flex-start;">
      <van-icon name="passed"
                color="#FF8200" />
      问诊已结束
    </div>

    <div v-else-if="newState === 5"
         style="justify-content: space-between;">
      <div class="left">
        <van-icon name="warning-o"
                  color="#FF8200" />
        服务单已关闭
      </div>
      <span class="state"
            style="color: #999999;">已取消</span>
    </div>

    <div v-else-if="newState === 3">
      <div class="left">
        <van-icon name="passed"
                  color="#FF8200" />
        医生已接诊，剩余问诊时间

        <van-count-down :time="serviceOrderEndTime"
                        format="HH时mm分ss秒"
                        @finish="changeState" />
      </div>
      <span class="state"
            style="color: #FF8200">服务中</span>
    </div>

    <div v-else-if="newState === 1">
      <div class="left">
        <van-icon name="passed"
                  color="#FF8200" />
        请尽快支付，
        <van-count-down :time="payexpiretime"
                        format="HH 时 mm 分 ss 秒"
                        @finish="changePayState" />后将自动关闭！
      </div>
      <span class="state"
            style="color: #FF8200">待支付</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['state', 'orderType', 'serviceOrderEndTime', 'payexpiretime'],
  data() {
    return {
      newState: 0,
    }
  },
  watch: {
    state: {
      handler(val) {
        // alert(typeof (val))
        this.newState = Number(val)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // alert(this.endTime - this.acceptTime)
  },
  // beforeRouteLeave(to, from, next) {
  //     // 关闭timer
  //     this.clearTimer()
  //     next()
  // }
  computed: {},
  methods: {
    changeState() {
      this.$emit('notifyFinsh', false)
    },
    changePayState() {
      this.$emit('notifyPayEnd', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.wait {
  background: #fffbe8;
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  position: relative;
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .van-icon {
      margin-right: 10px;
    }
  }
}
.van-count-down {
  display: inline;
  font-size: 13px;
}
.state {
  font-size: 13px;
}
</style>
